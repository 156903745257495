import { getStore } from "@/store/getStore";
import apiClient from "@/services/api/apiClient";
import { CallsListFilterModel } from "@/services/types/call";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver-es";
import { CriterionBaseModel } from "@/services/api/models/criterions";
import { makeSortByPositionFn } from "@/services/sortByPosition";
import { CategoryDetailedModel } from "@/services/api/models/categories";
import { getMmSsStringFromSeconds } from "@/services/formatting";
import moment from "moment";
import { EntityReportModel } from "@/services/api/reports/types";

function getRatingColor(rating: number) {
  const colors = {
    green: '93C47D',
    yellow: 'FFD966',
    red: 'EA9999',
  }

  if (rating >= 100) {
    return colors.green;
  } else if (rating >= 80) {
    return colors.yellow;
  } else {
    return colors.red;
  }
}

function multiplyCriteriaPositionWithCategoryPosition(checklistId: number, criteria: CriterionBaseModel[], categories: CategoryDetailedModel[]) {
  for (const criterion of criteria) {
    const category = categories.find(c => c.id === criterion.category.id);
    if (!category) continue;
    const position = category.position[checklistId];
    if (position === undefined) continue;

    if (criterion.position[checklistId] !== undefined) {
      criterion.position[checklistId] += 1000 * (position + 1);
    } else {
      criterion.position[checklistId] = 1000 * (position + 1);
    }
  }
}

function getMaxScore(estimatedCriteria: EntityReportModel["estimation"]["categories"][0]["criterions"]):number {
  return estimatedCriteria
    .filter((crt) => crt.selectedOptionId !== null) // filter out criteria with no selected option
    .reduce((acc, crt) => {
      const weights = crt.options
        .filter(i => i.id !== null && !!i.weight)
        .map(i => Number(i.weight));
      if (weights.length > 0) {
        return acc + Math.max(...weights);
      }
      return acc
    }, 0)
}

export async function generateReport() {
  const ectemUrl = process.env.VUE_APP_CLIENT_URL as string;
  const filter: CallsListFilterModel = JSON.parse(JSON.stringify(getStore().getters.getCallsFilterValue));
  filter.isEstimated = true;

  if (filter.estimated.from === null) {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    filter.estimated.from = oneWeekAgo.toISOString().split("T")[0];
  }

  const getAllCategories = apiClient.categoriesApi.getCategoriesAll().then(data => data.items);
  const getEntities = apiClient.reportsApi.getEntitiesReport({
    filter: {
      ...filter,
      types: ["CALL"],
    },
  });

  const [allCategories, entities] = await Promise.all([
    getAllCategories,
    getEntities
  ]);

  // const respItems = await import("./mockcalls.json").then(data => {
  //   return data.default as unknown as EntityReportModel[];
  // });

  const presentedChecklistsIds = Array.from(entities.reduce((acc, item) => {
    acc.add(item.estimation.checklistId);
    return acc;
  }, new Set<number>()));

  const workbook = new ExcelJS.Workbook();

  // for each presented checklist
  for (const checklistId of presentedChecklistsIds) {
    const filteredCalls = entities.filter(item => item.estimation.checklistId === checklistId);
    const checklist = await apiClient.checklistsApi.getChecklistWithCriterions(checklistId);
    multiplyCriteriaPositionWithCategoryPosition(checklistId, checklist.criterions, allCategories);
    const criteriaList = checklist.criterions.sort(makeSortByPositionFn(checklistId));

    // generate header row data
    const worksheet = workbook.addWorksheet(checklist.name);

    worksheet.columns = [
      {
        header: "Дата звонка",
        key: "startedAtDate",
        width: 12,
      },
      {
        header: "Время звонка",
        key: "startedAtTime",
        width: 10,
      },
      {
        header: "Длина звонка",
        key: "duration",
        width: 10,
      },
      {
        header: "Менеджер",
        key: "manager",
        width: 30,
      },
      {
        header: "Проект",
        key: "project",
        width: 30,
      },
      ...criteriaList.map(c => ({
        header: c.name,
        key: c.id.toString(),
      })),
      {
        header: "Max баллов",
        key: "maxScore",
      },
      {
        header: "Итог",
        key: "totalScore",
      },
      {
        header: "%",
        key: "rating",
      },
      {
        header: "Ссылка",
        key: "link",
        width: 30
      }
    ];



    for (const call of filteredCalls) {
      const estimationCriteria = call.estimation.categories.flatMap(c => c.criterions);

      const maxScore = getMaxScore(estimationCriteria);

      const totalScore = estimationCriteria.reduce((acc, crt) => {
        const selectedOption = crt.options.find(i => i.id === crt.selectedOptionId);
        return acc + (Number(selectedOption?.weight) || 0);
      }, 0)

      worksheet.addRow({
        startedAtDate: new Date(call.startedAt),
        startedAtTime: new Date(call.startedAt),
        duration: getMmSsStringFromSeconds(call.data.duration),
        manager: call.manager?.name,
        project: call.project?.name,

        ...criteriaList.reduce((acc, criteria) => {
          let value: number | string = "";
          const estimatedCriterion = estimationCriteria.find(c => c.id === criteria.id);
          if (estimatedCriterion) {
            const selectedOption = estimatedCriterion.options.find(i => i.id === estimatedCriterion.selectedOptionId);
            if (selectedOption && selectedOption.id !== null) {
              value = Number(selectedOption.weight);
            } else {
              value = 'n'
            }
          }

          acc[criteria.id] = value;
          return acc;
        }, {} as Record<string, number | string>),

        maxScore: maxScore,
        totalScore: totalScore,
        rating: `${call.estimation.rating}%`,
        link: {
          text: `${ectemUrl}calls?callId=${call.id}`,
          hyperlink: `${ectemUrl}calls?callId=${call.id}`
        }
      });
    }

    //#region STYLING
    worksheet.views = [
      { state: "frozen", ySplit: 1 },
    ];

    // style all cells
    worksheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        cell.alignment = {
          ...cell.alignment,
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        }
      });
    });

    // header row styling
    const headerRow = worksheet.getRow(1);
    headerRow.height = 150

    // style all header
    headerRow.eachCell({ includeEmpty: false }, (cell) => {
      cell.font = {
        name: "Roboto",
        size: 8,
        bold: true,
      }
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      }
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "F3F3F3" },
      }
    });

    // style criterion cells
    for (let i = 6; i < 6 + criteriaList.length; i++) {
      const cell = headerRow.getCell(i);
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "EAD1DC" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        textRotation: -90,
        wrapText: true,
      };
    }

    // style maxScore, totalScore, rating cells
    ['maxScore', 'totalScore', 'rating'].forEach((key) => {
      const column = worksheet.getColumn(key);
      const cell = headerRow.getCell(column.number);
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B6D7A8" },
      }
    })

    // style rating cells
    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      if (rowNumber <= 1) return

      const column = worksheet.getColumn('rating');
      const ratingCell = row.getCell(column.number);
      const ratingValue = parseInt(ratingCell.value.toString().replace('%', ''), 10);
      const color = getRatingColor(ratingValue)

      ratingCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      };
    });

    // style link cells
    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      if (rowNumber <= 1) return
      const column = worksheet.getColumn('link');
      const cell = row.getCell(column.number);
      cell.alignment = {
        ...cell.alignment,
        wrapText: false,
      }
    });

    // set date format
    worksheet.getColumn("startedAtDate").eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (rowNumber > 1) {
        cell.numFmt = "dd.mm.yyyy";
      }
    });
    worksheet.getColumn("startedAtTime").eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (rowNumber > 1) {
        cell.numFmt = "hh:mm";
      }
    });
    //#endregion
  }

  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

  const dateFrom = moment(filter.estimated.from).format("DD.MM.YYYY");
  const dateTo = moment(filter.estimated.to ?? new Date()).format("DD.MM.YYYY");
  const filename = `Отчет: ${dateFrom} - ${dateTo}`

  saveAs(blob, filename+".xlsx");
}